import { BaseModel } from '../base.model';
import { ILockProvider } from '../lock-provider-config.model';

export class SymmetryLockProviderModel extends BaseModel implements ILockProvider {

    initialCardStack: number;
    cardStackWarningLimit: number;
    companyId: number;
    accessGroupId: number;
    elevatorGroupId: number;
    defaultElevatorGroupData: string;
    customerCode: number;
    url: string;
    wsdlUrl: string;
    username: string;
    password: string;
    namespace: string;
    stsNamespace: string;
    adminEmail: string;

    hasCardDispenser: boolean;
    hasElevatorGroup: boolean;

    get initialValues() {
        return {
            hasElevatorGroup: true,
            hasCardDispenser: true,
            roleIds: [],
            lockIds: []
        }
    }

}

<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div class="col-12 col-md-10 col-lg-8 col-xl-6 col-xxl-4">
          <div>
            <a class="logo" routerLink='/'>
              <img class="img-fluid for-light" src="{{logoUrl}}" alt="looginpage">
              <img class="img-fluid for-dark" src="{{logoDarkUrl}}" alt="looginpage">
            </a>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-auto cal-date-widget mb-4 mb-md-0 py-0" *ngIf="attendee?.getMyStartTimeObject()">
                  <div class="cal-info text-center">
                    <h2>{{ attendee?.getMyStartTimeObject() | date:'dd'}}</h2>
                    <div class="d-inline-block"> {{ attendee?.getMyStartTimeObject() | date:'MMMM y'}} </div>
                  </div>
                </div>
                <div class="col-md">
                  <h4 *ngIf="attendee?.appointment?.title" class="m-0 text-center text-md-left">
                    {{attendee?.appointment?.title}}
                  </h4>
                  <p class="my-2 text-center text-md-left">
                    <span class="d-block d-md-inline-block" *ngIf="attendee?.getMyStartTimeObject()">

                      {{ 'Opening time:' | translate }}
                      <span class="font-weight-bold">
                        {{ attendee?.getMyStartTimeObject() | date:'d/MM/y, HH:mm'}}
                      </span>
                    </span>
                    <span class="d-none d-md-inline-block pr-3 b-r-dark mr-3"
                      *ngIf="attendee?.getMyEndTimeObject()">&nbsp;</span>
                    <span class="d-block d-md-inline-block" *ngIf="attendee?.getMyEndTimeObject()">
                      {{ 'Closing time:' | translate }}
                      <span class="font-weight-bold">
                        {{ attendee?.getMyEndTimeObject() | date:'d/MM/y, HH:mm'}}
                      </span>
                    </span>
                  </p>
                  <p *ngIf="attendee?.appointment?.description" class="text-muted">
                    {{attendee?.appointment?.description}}
                  </p>
                </div>
              </div>

              <div *ngIf="showSuccess" class="mt-5">
                <div class="d-flex justify-content-center">
                  <div class="col-auto">
                    <img alt="" [src]="attendee?.getPhotoUrl()" class="cover-photo rounded-circle">
                  </div>
                  <div class="col-auto d-flex align-items-center">
                    <h3 class="mb-1">{{ attendee?.getFullName() }}</h3>
                  </div>
                </div>
                <div class="text-center">
                  <div class="py-4">
                    <h5 class="">
                      <i class="fa fa-check-circle text-success mr-2"></i>
                      {{ 'Thank you!' | translate }}
                    </h5>
                    <p class="">
                      {{ 'Attendee information has been successfully stored. See you at the meeting!' | translate }}
                    </p>
                  </div>
                  <p class="text-muted">
                    {{ 'You made a mistake? No worries!' | translate }}
                    <button class="btn btn-link p-0" (click)="showSuccess=false">
                      {{ 'Update again' | translate }}
                    </button>
                  </p>
                  <p class="lead">
                    <a class="btn btn-primary" [href]="homepage" role="button">
                      {{ 'Go to meeting homepage' | translate }}
                    </a>
                  </p>
                </div>
              </div>

              <form *ngIf="!showSuccess" [formGroup]="form" (onSubmit)="saveAttendee()">

                <p class="mt-4 text-muted">
                  {{
                  'ATTENDEE_INVITE_INFO' | translate
                  }}
                </p>

                <div class="attendee-email">
                  <h4 class="text-center">
                    {{attendee?.profile?.email}}
                  </h4>
                  <h6 *ngIf="attendee?.accessCardStatus" class="font-weight-normal m-0 text-muted text-center">
                    <i class="fa fa-id-card"></i>
                    {{ attendee?.accessCardNumber }}
                    <span *ngIf="attendee?.accessCardStatus" [class.badge-warning]="attendee?.accessCardStatus == 'issued'" class="badge badge-pill text-capitalize font-weight-normal badge-light px-2 py-1">
                      {{attendee?.accessCardStatus | translate}}
                    </span>
                  </h6>
                </div>

                <div class="alert alert-warning" *ngIf="attendee?.accessCardStatus == 'issued'">
                  <p>
                    {{ 'Please make sure to return access card, once your meeting ends.' | translate }}
                  </p>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{'Personal Title' | translate}}</label>
                      <input formControlName="personalTitle" type="text" class="form-control"
                        placeholder="{{'Mr, Prof. dr, Her Excellency' | translate}}">
                      <div *ngIf="personalTitle.invalid && (personalTitle.dirty || personalTitle.touched)"
                        class="text-danger">
                        <small *ngIf="personalTitle.errors?.maxLength">
                          {{'Personal title can be max 45 characters long.' | translate}}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{'First Name' | translate}}</label>
                      <input formControlName="firstName" type="text" class="form-control"
                        placeholder="{{'First Name' | translate}}">
                      <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)" class="text-danger">
                        <small *ngIf="firstName.errors?.maxLength">
                          {{'First name can be max 45 characters long.' | translate}}
                        </small>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{'Last Name' | translate}}</label>
                      <input formControlName="lastName" type="text" class="form-control"
                        placeholder="{{'Last Name' | translate}}">
                      <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)" class="text-danger">
                        <small *ngIf="lastName.errors?.maxLength">
                          {{'Last name can be max 45 characters long.' | translate}}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group mb-0">
                  <button class="btn btn-primary btn-block" [class.loader--text]="showLoader"
                    [disabled]="!form.valid || showLoader" (click)="saveAttendee()" type="submit">
                    <span>
                      {{ showLoader ? '' : ('Update' | translate) }}
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

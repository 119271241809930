import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AttendeeModel } from '../models/attendee.model';
import { AttendeeService } from '../services/attendee.service';

@Injectable()
export class AttendeeResolver implements Resolve<AttendeeModel> {
  constructor(
    private attendeeService: AttendeeService,
    private router: Router,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<AttendeeModel> {
    let attendeeHash = route.paramMap.get('attendee');

    return new Promise((resolve, reject) => {
      this.attendeeService.getAttendee(attendeeHash, { expand: 'profile.image,appointment' }).subscribe((attendee: AttendeeModel) => {
        if (!attendee) {
          reject();
          return this.router.navigate(['404']);
        }
        attendee.authHash = attendeeHash;
        
        resolve(attendee);
      }, (err: any) => {
        return this.router.navigate(['404']);
      });
    });
  }
}
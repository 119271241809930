import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { AppointmentCalendarModel, AppointmentModel } from '../models/appointment.model';
import { GridDataInterface, PaginationModel } from '../models/pagination.model';
import { Observable, of } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { catchError, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslationService } from './translation.service';
import { AttendeeModel } from '../models/attendee.model';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  constructor(
    private authService: AuthService,
    private api: BaseApiService,
    private translationService: TranslationService,
    private toast: ToastrService
  ) {
  }

  saveAppointment(appointment: AppointmentModel, queryParams: any = {}): Observable<AppointmentModel | unknown> {
    const appointmentApiData = new AppointmentModel({}).toApi(appointment);

    return this.api.postFormData(`/appointments/${appointment.id}`, appointmentApiData, queryParams).pipe(
      map(response => {
        if (response?.body?.success == false) {
          this.toast.error('Ooops, something went wrong');
          return undefined;
        }

        return new AppointmentModel(response.body);
      }),
      catchError((err) => {
        this.toast.error(err.error.errors.join("\n"));
        return of(undefined);
      }),
    );
  }

  saveAppointmentCalendar(appointment: AppointmentModel, queryParams: any = {}): Observable<AppointmentModel | unknown> {
    const appointmentApiData = new AppointmentCalendarModel(appointment).toApi();

    return this.api.postFormData(`/appointments/calendar`, appointmentApiData, queryParams).pipe(
      map(response => {
        if (response?.body?.success == false) {
          this.toast.error('Ooops, something went wrong');
          return undefined;
        }

        return new AppointmentModel(response.body);
      }),
      catchError((err) => {
        console.log(err)
        this.toast.error(err.message);
        return of(undefined);
      }),
    );
  }

  removeAppointment(hash: string | number): Observable<AppointmentModel | unknown> {

    return this.api.delete(`/appointments/${hash}`).pipe(
      map(response => {
        if (response?.body?.success == false) {
          this.toast.error('Ooops, something went wrong');
          return undefined;
        }

        return new AppointmentModel(response.body);
      }),
      catchError((err) => {
        this.toast.error(err.error.errors?.join("\n") || err.message);
        return of(undefined);
      }),
    );
  }

  getAppointment(hash: string | null, queryParams: any = {}): Observable<AppointmentModel> {
    return this.api.get(`/appointments/${hash}`, queryParams).pipe(
      map((response) => {
        if (response?.body?.success == false) {
          this.toast.error('Ooops, something went wrong');
          return undefined;
        }

        return new AppointmentModel(response?.body);
      })
    );
  }

  getAppointments(queryParams: any = {}): Observable<GridDataInterface | undefined> {
    return this.api.get(`/appointments`, queryParams).pipe(
      map((response) => {
        if (response?.body?.success == false) {
          this.toast.error('Ooops, something went wrong');
          return undefined;
        }

        return {
          data: response.body.map(appointment => new AppointmentModel(appointment)),
          pagination: PaginationModel.createFromHeaders(response.headers)
        };
      })
    );
  }

  getAppointmentAttendee(appointmentHash: string | null, attendeeHash: string | null, queryParams: any = {}): Observable<AttendeeModel | unknown> {

    return this.api.get(`/appointments/${appointmentHash}/attendee/${attendeeHash}`, queryParams).pipe(
      map(response => {
        if (response?.body?.success == false) {
          this.toast.error('Ooops, something went wrong');
          return undefined;
        }

        return new AttendeeModel(response.body);
      }),
      catchError((err) => {
        this.toast.error(err.error.errors?.join("\n") || err.message);
        return of(undefined);
      }),
    );
  }

  saveAppointmentAttendee(appointmentHash: string, attendeeHash: string, attendee: AttendeeModel, queryParams: any = {}): Observable<AttendeeModel | unknown> {
    const attendeeApiData = new AttendeeModel({}).toApi(attendee);

    return this.api.postFormData(`/appointments/${appointmentHash}/attendee/${attendeeHash}`, attendeeApiData, queryParams).pipe(
      map(response => {
        if (response?.body?.success == false) {
          this.toast.error('Ooops, something went wrong');
          return undefined;
        }

        return new AttendeeModel(response.body);
      }),
      catchError((err) => {
        this.toast.error(err.error.errors.join("\n"));
        return of(undefined);
      }),
    );
  }

}

<form [formGroup]="form" (ngSubmit)="save()">
    <div class="modal-header">
        <h4 class="modal-title">{{((attendee?.profile?.email && attendee.getFullName() ? attendee.getFullName() : attendee.profile?.email) || 'Create attendee') | translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">

        <p class="text-muted">
            {{
            'Attendee has been invited to the following meeting. Please use form bellow to update person
            information, which will be used to properly salute attendee on a Digital reception.' | translate
            }}
        </p>

        <div class="row">
            <div class="col-md-6" *ngIf="!attendee.mail">
                <div class="form-group">
                    <label>{{'Attendee is host' | translate}}</label>
                    <label class="switch d-block">
                        <input type="checkbox" formControlName="isHost">
                        <span class="switch-state"></span>
                    </label>
                </div>
            </div>
        </div>

        <div class="row">
            <div *ngIf="!attendee.mail" class="col-md-6">
                <div class="form-group">
                    <label>{{'Email' | translate}}</label>
                    <input formControlName="email" type="text" class="form-control" placeholder="{{'john.doe@example.com' | translate}}">
                    <div *ngIf="email.invalid && (personalTitle.dirty || email.touched)"
                        class="text-danger">
                        <small *ngIf="email.errors?.email">
                            {{'Email should be a valid email address.' | translate}}
                        </small>
                        <small *ngIf="email.errors?.required">
                            {{'Email address is mandatory.' | translate}}
                        </small>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{'Personal Title' | translate}}</label>
                    <input formControlName="personalTitle" type="text" class="form-control"
                        placeholder="{{'Mr.' | translate}}">
                    <div *ngIf="personalTitle.invalid && (personalTitle.dirty || personalTitle.touched)"
                        class="text-danger">
                        <small *ngIf="personalTitle.errors?.maxlength">
                            {{'Personal title can be max 45 characters long.' | translate}}
                        </small>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{'First Name' | translate}}</label>
                    <input formControlName="firstName" type="text" class="form-control"
                        placeholder="{{'John' | translate}}">
                    <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)" class="text-danger">
                        <small *ngIf="firstName.errors?.maxlength">
                            {{'First name can be max 45 characters long.' | translate}}
                        </small>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label>{{'Last Name' | translate}}</label>
                    <input formControlName="lastName" type="text" class="form-control"
                        placeholder="{{'Doe' | translate}}">
                    <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)" class="text-danger">
                        <small *ngIf="lastName.errors?.maxlength">
                            {{'Last name can be max 45 characters long.' | translate}}
                        </small>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="modal-footer">
        <div class="right-buttons">
            <button type="button" (click)="closeModal()" class="btn btn-light">
                {{'Cancel' | translate}}
            </button>
            <button type="submit" class="btn btn-primary" [disabled]="isSaving">
                {{'Save' | translate}}
                <span *ngIf="isSaving" class="spinner-border spinner-border-sm ml-2" role="status">
                    <span class="sr-only">Loading...</span>
                </span>
            </button>
        </div>
    </div>

</form>
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { UserModel } from '../models/user.model';
import { GridDataInterface, PaginationModel } from '../models/pagination.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { catchError, map } from 'rxjs/operators';
import { TranslationService } from './translation.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private USER = new BehaviorSubject<UserModel>(undefined);
  user = this.USER.asObservable();

  get userInfo(): UserModel {
    return this.USER.value;
  }

  constructor(
    private authService: AuthService,
    private api: BaseApiService,
    private toast: ToastrService,
    private translationService: TranslationService,
  ) {
    AuthService.LOGIN.subscribe(() => {
      this.initializeLanguage();
    });

    TranslationService.TRANSLATED.subscribe(lang => {
      this.changeUserLanguage(lang);
    });
  }

  initializeLanguage() {
    return this.authService.getUserByToken().subscribe((user: UserModel) => {
      if (user?.profile?.language) {
        this.translationService.translateTo(user.profile.language);
        this.translationService.setLanguageToStorage(user.profile.language);
      }
      this.USER.next(new UserModel(user));
    });
  }

  private changeUserLanguage(lang: string): void {
    this.api.put(`/users/me`, { language: lang }).subscribe();
  }

  saveUser(user: UserModel): Observable<UserModel | unknown> {
    const userApiData = new UserModel({}).toApi(user);
    return this.api.postFormData(`/users${user.id ? '/' + user.id : ''}`, userApiData).pipe(
      map(response => {
        if (response?.body?.success == false) {
          return undefined;
        }
        return new UserModel(response.body);
      }),
      catchError((e) => {
        this.toast.error(e.message);
        return of(undefined);
      }),
    );
  }

  saveUserInfo(user: UserModel): Observable<UserModel> {
    const userApiData = new UserModel({}).toApi(user);
    return this.api.postFormData(`/users/me`, userApiData).pipe(
      map(response => {
        if (response?.body?.success == false) {
          return undefined;
        }
        return new UserModel(response.body);
      }),
      catchError((e) => {
        this.toast.error(e.message);
        return of(undefined);
      }),
    );
  }

  getUsers(queryParams: any = {}): Observable<GridDataInterface> {
    queryParams.expand = queryParams.expand ? queryParams.expand : 'company';
    return this.api.get(`/users`, queryParams).pipe(
      map((response) => {
        if (response?.body?.success == false) {
          return undefined;
        }
        return {
          data: response.body.map(user => new UserModel(user)),
          pagination: PaginationModel.createFromHeaders(response.headers)
        };
      }),
      catchError((e) => {
        this.toast.error(e.message);
        return of(undefined);
      }),
    );
  }

  removeUser(id: string | number): Observable<UserModel | unknown> {
    return this.api.delete(`/users/${id}`).pipe(
      map(response => {
        if (response?.body?.success == false) {
          return undefined;
        }
        return new UserModel(response.body);
      }),
      catchError((e) => {
        this.toast.error(e.message);
        return of(undefined);
      }),
    );
  }

  setInstance(instanceId: number): Observable<UserModel | undefined> {
    return this.api.put(`/users/me`, { instance_id: instanceId }, {expand: 'profile.image,company.image,instance.image,instances'}).pipe(
      map(response => {
        if (response?.body?.success == false) {
          return undefined;
        }
        return new UserModel(response.body);
      }),
      catchError((e) => {
        this.toast.error(e.message);
        return of(undefined);
      }),
    );
  }


}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ImageSnippet } from './image-snippet.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent {
  private selectedFile: ImageSnippet | undefined;
  readonly MAX_UPLOAD_SIZE = 5000000;

  constructor(
    private toast: ToastrService
  ) {
  }

  @Input() imageUrl: string;
  @Input() placeholderImage = '/assets/images/user/user.png';
  @Input() cssClass = 'cover';
  @Input() showDescription: boolean = true;
  @Output() image = new EventEmitter<any>();


  getPic() {
    if (this.selectedFile) {
      return `url('${this.selectedFile.src}')`;
    }

    if (this.imageUrl != '') {
      return `url('${this.imageUrl}')`;
    }

    return 'none';
  }

  deletePic() {
    this.imageUrl = '';
    this.selectedFile = undefined;
  }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    if (file.size <= this.MAX_UPLOAD_SIZE) {
      const reader = new FileReader();
      reader.addEventListener('load', (event: any) => {
        this.selectedFile = new ImageSnippet(event.target.result, file);
        this.image.emit(this.selectedFile.file);
      });
      reader.readAsDataURL(file);
    } else {
      this.toast.error('The image you selected is too large.');
    }
  }
}

<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div class="col-12 col-md-10 col-lg-8 col-xl-6 col-xxl-4">
          <div>
            <a class="logo" routerLink='/'>
              <img class="img-fluid for-light" src="{{logoUrl}}" alt="looginpage">
              <img class="img-fluid for-dark" src="{{logoDarkUrl}}" alt="looginpage">
            </a>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-auto cal-date-widget mb-4 mb-md-0 py-0" *ngIf="appointment?.getStartTimeObject()">
                  <div class="cal-info text-center">
                    <h2>{{ appointment?.getStartTimeObject() | date:'dd'}}</h2>
                    <div class="d-inline-block"> {{ appointment?.getStartTimeObject() | date:'MMMM y'}} </div>
                  </div>
                </div>
                <div class="col-md">
                  <h4 *ngIf="appointment?.title" class="m-0 text-center text-md-left">
                    {{appointment?.title}}
                  </h4>
                  <p class="my-2 text-center text-md-left">
                    <span class="d-block d-md-inline-block" *ngIf="appointment?.getStartTimeObject()">
                      {{ 'Opening time:' | translate }}
                      <span class="font-weight-bold">
                        {{ appointment?.getStartTimeObject() | date:'d/MM/y, HH:mm'}}
                      </span>
                    </span>
                    <span class="d-none d-md-inline-block pr-3 b-r-dark mr-3"
                      *ngIf="appointment?.getEndTimeObject()">&nbsp;</span>
                    <span class="d-block d-md-inline-block" *ngIf="appointment?.getEndTimeObject()">
                      {{ 'Closing time:' | translate }}
                      <span class="font-weight-bold">
                        {{ appointment?.getEndTimeObject() |  date:'d/MM/y, HH:mm'}}
                      </span>
                    </span>
                  </p>
                  <p *ngIf="appointment?.description" class="text-muted">
                    {{appointment?.description}}
                  </p>
                </div>
              </div>

              <div *ngIf="showRemoved" class="mt-5">
                <div class="text-center">
                  <h3 class="mb-1 text-center">{{ 'Meeting successfully canceled!' | translate }}</h3>
                  <div class="py-4">
                    <h5 class="">
                      <i class="fa fa-check-circle text-success mr-2"></i>
                      {{ 'Thank you!' | translate }}
                    </h5>
                    <p class="">
                      {{ 'Meeting has been succesfully canceled, and all access rights for visitors have been revoked.' | translate }}
                    </p>
                  </div>
                </div>
              </div>

              <div *ngIf="showSuccess" class="mt-5">
                <div class="text-center">
                  <h3 class="mb-1 text-center">{{ 'Meeting successfully updated!' | translate }}</h3>
                  <div class="py-4">
                    <h5 class="">
                      <i class="fa fa-check-circle text-success mr-2"></i>
                      {{ 'Thank you!' | translate }}
                    </h5>
                    <p class="">
                      {{ 'Attendee information has been successfully stored, and email with access instructions is sent to new attendees. See you at the meeting!' | translate }}
                    </p>
                  </div>
                  <p class="text-muted">
                    {{ 'You made a mistake? No worries!' | translate }}
                    <button class="btn btn-link p-0" (click)="showSuccess=false; showForm=true">
                      {{ 'Update again' | translate }}
                    </button>
                  </p>
                  <p class="lead">
                    <a class="btn btn-primary" [href]="homepage" role="button">
                      {{ 'Go to meeting homepage' | translate }}
                    </a>
                  </p>
                </div>
              </div>

              <form *ngIf="showForm" [formGroup]="form" (onSubmit)="saveAppointment()">

                <p class="mt-4 text-muted">
                  {{
                  'ATTENDEE_UPDATE_INFO_TEXT' | translate
                  }}
                </p>

                <div *ngIf="showLMeetingOptions">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label>{{'Meeting Title' | translate}}</label>
                        <input formControlName="title" type="text" class="form-control"
                          placeholder="{{'Mr, Prof. dr, Her Excellency' | translate}}">
                        <div *ngIf="title.invalid && (title.dirty || title.touched)"
                          class="text-danger">
                          <small *ngIf="title.errors?.maxLength">
                            {{'Meeting title can be max 256 characters long.' | translate}}
                          </small>
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-6">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label>{{'Start Date' | translate}}</label>
                            <input formControlName="startDateObject" type="date" class="form-control digits">
                            <div *ngIf="startDateObject.invalid && (startDateObject.dirty || startDateObject.touched)"
                              class="text-danger">
                              <small *ngIf="startDateObject.errors?.required">
                                {{'Meeting start date is required.' | translate}}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6 d-flex align-items-center">
                          <button *ngIf="!showStartTime" class="btn btn-link mt-2 px-0" (click)="showStartTime = true">{{'Add Time' |translate}}</button>
                          <div *ngIf="showStartTime" class="form-group w-100">
                            <label>{{'Start Time' | translate}}</label>
                            <input formControlName="startTimeObject" type="time" class="form-control digits">
                            <div *ngIf="startTimeObject.invalid && (startTimeObject.dirty || startTimeObject.touched)"
                              class="text-danger">
                              <small *ngIf="startTimeObject.errors?.required">
                                {{'Meeting start time is required.' | translate}}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-6">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label>{{'End Date' | translate}}</label>
                            <input formControlName="endDateObject" type="date" class="form-control"
                              placeholder="{{'12:00 AM' | translate}}">
                            <div *ngIf="endDateObject.invalid && (endDateObject.dirty || endDateObject.touched)"
                              class="text-danger">
                              <small *ngIf="endDateObject.errors?.required">
                                {{'Meeting end time is required.' | translate}}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6 d-flex align-items-center">
                          <button *ngIf="!showEndTime" class="btn btn-link mt-2 px-0" (click)="showEndTime = true">{{'Add Time' |translate}}</button>
                          <div *ngIf="showEndTime" class="form-group w-100">
                            <label>{{'End Time' | translate}}</label>
                            <input formControlName="endTimeObject" type="time" class="form-control"
                              placeholder="{{'12:00 AM' | translate}}">
                            <div *ngIf="endTimeObject.invalid && (endTimeObject.dirty || endTimeObject.touched)"
                              class="text-danger">
                              <small *ngIf="endTimeObject.errors?.required">
                                {{'Meeting end time is required.' | translate}}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label>{{'Meeting Description' | translate}}</label>
                        <textarea formControlName="description" type="text" class="form-control"
                          placeholder="{{'Short meeting description' | translate}}">
                        </textarea>
                        <div *ngIf="description?.invalid && (description?.dirty || description?.touched)"
                          class="text-danger">
                          <small *ngIf="description.errors?.maxLength">
                            {{'Meeting title can be max 512 characters long.' | translate}}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 text-right form-group">
                    <button type="submit" class="btn btn-light mr-2" (click)="showLMeetingOptions=!showLMeetingOptions">
                        {{(showLMeetingOptions ? 'Hide meeting options' : 'Show meeting options') | translate}}
                    </button>

                    <button type="submit" class="btn btn-light" (click)="showCreateAttendeeModal()">
                        {{'Add new attendee' | translate}}
                    </button>
                  </div>
                </div>

                <div *ngFor="let attendee of appointment?.getSortedAttendees()" class="row mb-3">
                  <div class="col-auto">
                    <img alt="" [src]="attendee?.getPhotoUrl()" class="cover-photo rounded-circle">
                  </div>
                  <div class="col d-flex align-items-center">
                    <div class="d-flex-row">
                      <h5 class="font-weight-normal m-0" *ngIf="attendee.getFullName()">{{ attendee?.getFullName() }}</h5>
                      <h6 class="font-weight-normal m-0 text-muted">
                        {{ attendee?.profile?.email }}
                        <span *ngIf="attendee.isHost" class="badge badge-light-primary font-weight-normal badge-pill ml-2">{{'Host' | translate}}</span>
                      </h6>
                      <h6 *ngIf="attendee?.accessCardStatus" class="font-weight-normal m-0 text-muted">
                        <i class="fa fa-id-card"></i>
                        {{ attendee?.accessCardNumber }}
                        <span *ngIf="attendee?.accessCardStatus" [class.badge-warning]="attendee?.accessCardStatus == 'issued'" class="badge badge-pill text-capitalize font-weight-normal badge-light px-2 py-1">
                          {{attendee?.accessCardStatus | translate}}
                        </span>
                      </h6>
                    </div>
                  </div>
                  <div class="col-auto d-flex align-items-center">
                    <div class="d-flex-column">
                      <button class="btn btn-xs btn-light mr-2" (click)="showUpdateAttendeeModal(attendee)"><i class="fa fa-edit"></i></button>
                      <button
                        type="button"
                        class="btn btn-xs btn-outline-danger"
                        *ngIf="canRemoveAttendee(attendee)"
                          (confirm)="removeAttendee(attendee)"
                          [disabled]="isSaving"
                          [swal]="{title: 'REMOVE_ATTENDEE_TITLE' | translate, text: ('REMOVE_ATTENDEE_TEXT' | translate : {attendee:(attendee.getFullName() || attendee.profile.email)}), cancelButtonText: 'Cancel' | translate}"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="mt-5 d-flex justify-content-between">
                  <button type="button" (confirm)="removeAppointment()" class="btn btn-outline-danger" [disabled]="showLoader"
                      [swal]="{title: 'REMOVE_APPOINTMENT_TITLE' | translate, text: ('REMOVE_APPOINTMENT_TEXT' | translate : {appointment:appointment.title}), cancelButtonText: 'Cancel' | translate}">
                      {{'Cancel Appointment' | translate}}
                  </button>

                  <div class="right-buttons">
                    <a class="btn btn-light mr-2" [href]="homepage" role="button">
                      {{'Cancel' | translate}}
                    </a>
                    <button type="submit" class="btn btn-primary" [disabled]="!form.valid || showLoader" (click)="saveAppointment()">
                        {{'Save' | translate}}
                        <span *ngIf="isSaving" class="spinner-border spinner-border-sm ml-2" role="status">
                            <span class="sr-only">Loading...</span>
                        </span>
                    </button>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

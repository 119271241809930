import { BaseModel } from './base.model';

export class CardDispenserModel extends BaseModel {

  hasCardDispenser: boolean;
  cardsOriginalStack: number;
  cardsIssued: number;
  cardsAvailable: number;
  cardsWarningLimit: number;

  get isWarningLimit() {
    return this.cardsAvailable <= this.cardsWarningLimit;
  }
}

import { AppointmentModel } from "./appointment.model";
import { BaseModel } from "./base.model";
import { ProfileModel } from "./profile.model";
import { AuditLogModel } from "./audit-log.model";

export class AttendeeModel extends BaseModel {
    id: number;
    authCode: string;
    authHash: string;
    accessCardNumber: string;
    accessCardStatus: string;
    lockCode: string;
    startTime: string;
    endTime: string;
    status: string;
    isHost: 0 | 1;

    defaultImageUrl = '/assets/images/user/user.png'

    profile: ProfileModel;
    appointment: AppointmentModel;
    auditLogs: AuditLogModel[];

    get childModels(): {} {
        return {
            profile: ProfileModel,
            appointment: AppointmentModel,
            auditLogs: AuditLogModel,
        };
    }

    get unsafeApiAttributes() {
        return [
            'id', 'defaultImageUrl', 'mail', 'email', 'name', 'surname', 'auditLogs',
            'startDateTime', 'finishDateTime', 'authHash', 'authCode', 'lockCode',
        ];
    }

    getFullName() {
        return `${(this.profile?.personalTitle || '')} ${this.profile?.firstName || ''} ${this.profile?.lastName || ''}`.trim();
    }

    getPhotoUrl() {
        return this.profile?.image?.baseUrl || this.defaultImageUrl;
    }

    getStartTimeObject() {
        if (!this.startTime) {
            return null;
        }

        return new Date(this.startTime);
    }

    getEndTimeObject() {
        if (!this.endTime) {
            return null;
        }

        return new Date(this.endTime);
    }

    getMyStartTimeObject() {
        return this.getStartTimeObject() || this.appointment?.getStartTimeObject();
    }

    getMyEndTimeObject() {
        return this.getEndTimeObject() || this.appointment?.getEndTimeObject();
    }

    getAuditLogFields(fields) {
        return this.auditLogs.filter(auditLog => fields.includes(auditLog.field));
    }

    canSendBadgeReturnReminder() {
        return this.isAccessCardIssued || this.isAccessCardRevoked;
    }

    canRevokeAccess() {
        return this.isAccessCardIssued;
    }

    canReturnBadge() {
        return this.isAccessCardIssued || this.isAccessCardRevoked;
    }

    get hasAccessCard() {
        return this.isAccessCardIssued;
    }

    get isAccessCardIssued() {
        return this.accessCardStatus == 'issued';
    }

    get isAccessCardReturned() {
        return this.accessCardStatus == 'returned';
    }

    get isAccessCardRevoked() {
        return this.accessCardStatus == 'revoked';
    }

    get isInvited() {
        return this.status == 'invited';
    }

    get isCheckedIn() {
        return this.status == 'checked-in';
    }

    get isCheckedOut() {
        return this.status == 'checked-out';
    }
}

export class AttendeeCalendarModel extends BaseModel {

    email: string;
    authHash: string;
    firstName: string | null = null;
    lastName: string;
    personalTitle: string;
    startTime: string;
    endTime: string;
    status: string;
    isHost: 0 | 1;

    profile: ProfileModel;

    toApi(model?: this) {
        this.setAttributes(this.profile || {});

        return super.toApi(model);
    }

    get childModels(): {} {
        return {
            profile: ProfileModel,
        };
    }

    get unsafeApiAttributes() {
        return [
            'personalTitle', 'defaultImageUrl', 'name', 'surname', 'mail', 'profile',
            'startDateTime', 'finishDateTime', 'authHash', 'authCode', 'lockCode',
        ];
    }
}

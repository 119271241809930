import {BaseModel} from "./base.model";
import {AttendeeCalendarModel, AttendeeModel} from "./attendee.model";
import {CompanyModel} from "./company.model";

export class AppointmentModel extends BaseModel {
  id: number;
  uid: string;
  title: string;
  description: string;
  startTime: string;
  endTime: string;
  authCode: string;
  authHash: string;

  company: CompanyModel
  attendees: AttendeeModel[]
  hosts: AttendeeModel[]
  visitors: AttendeeModel[]

  get childModels(): {} {
    return {
      company: CompanyModel,
      attendees: AttendeeModel,
    };
  }

  getSortedAttendees() {
    return this.attendees.sort((a, b) => b.isHost - a.isHost);
  }

  getHosts(){
   return this.attendees.filter(attendee =>
       attendee.isHost
    )
  }

  getVisitors(){
    return this.attendees.filter(attendee=>
       !attendee.isHost
    );
  }

  hasStartTime() {
    const start = this.getStartTimeObject();

    return start && (start.getHours() != 0 || start.getMinutes() != 0 || start.getSeconds() != 0);
  }

  hasEndTime() {
    const end = this.getEndTimeObject();

    return end && (end.getHours() != 0 || end.getMinutes() != 0 || end.getSeconds() != 0);
  }

  getStartTimeObject(): Date | null {
    if (!this.startTime) {
      return null;
    }

    return new Date(this.startTime);
  }

  getEndTimeObject(): Date | null {
    if (!this.endTime) {
      return null;
    }

    return new Date(this.endTime);
  }

  setStartTimeISO(value: Date) {
    if (!value) {
      return;
    }

    value = value instanceof Date ? value : new Date(value);

    let start: Date = this.getStartTimeObject();
    if (start) {
      start.setUTCSeconds(value.getUTCSeconds());
      start.setUTCMinutes(value.getUTCMinutes());
      start.setUTCHours(value.getUTCHours());
    } else {
      start = value;
    }

    this.startTime = this.getISOStringWithoutMilliseconds(start);
  }

  setStartDateISO(value: Date) {
    if (!value) {
      return;
    }

    value = value instanceof Date ? value : new Date(value);

    let start: Date = this.getStartTimeObject();
    if (start instanceof Date) {
      start.setUTCDate(value.getUTCDate());
      start.setUTCMonth(value.getUTCMonth());
      start.setUTCFullYear(value.getUTCFullYear());
    } else {
      start = value;
    }

    this.startTime = this.getISOStringWithoutMilliseconds(start);
  }

  setEndTimeISO(value: Date) {
    if (!value) {
      return;
    }

    value = value instanceof Date ? value : new Date(value);

    let end: Date = this.getEndTimeObject();
    if (end) {
      end.setUTCSeconds(value.getUTCSeconds());
      end.setUTCMinutes(value.getUTCMinutes());
      end.setUTCHours(value.getUTCHours());
    } else {
      end = value;
    }

    this.endTime = this.getISOStringWithoutMilliseconds(end);
  }

  setEndDateISO(value: Date) {
    if (!value) {
      return;
    }

    value = value instanceof Date ? value : new Date(value);

    let end: Date = this.getEndTimeObject();
    if (end instanceof Date) {
      end.setUTCDate(value.getUTCDate());
      end.setUTCMonth(value.getUTCMonth());
      end.setUTCFullYear(value.getUTCFullYear());
    } else {
      end = value;
    }

    this.endTime = this.getISOStringWithoutMilliseconds(end);
  }

  private getISOStringWithoutMilliseconds(date: Date) {
    const s = date.toISOString();

    return s.substring(0, s.indexOf('.'));
  }
}

export class AppointmentCalendarModel extends BaseModel {

  uid: string;
  authHash: string;
  title: string;
  description: string;
  startDateTime: string;
  finishDateTime: string;

  company: CompanyModel
  hosts: AttendeeCalendarModel[];
  visitors: AttendeeCalendarModel[];
  attendees: AttendeeCalendarModel[];

  get unsafeApiAttributes() {
    return ['attendees'];
  }

  get childModels(): {} {
    return {
      company: CompanyModel,
      hosts: AttendeeCalendarModel,
      visitors: AttendeeCalendarModel,
      attendees: AttendeeCalendarModel,
    };
  }

  set startTime(value) {
    this.startDateTime = value;
  }

  set endTime(value) {
    this.finishDateTime = value;
  }

  get startTime() {
    return this.startDateTime;
  }

  get endTime() {
    return this.finishDateTime;
  }
}

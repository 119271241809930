import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../services/auth.service';
import { UserModel } from '../../../../models/user.model';
import { filter } from 'rxjs/operators';
import { UserFormComponent } from '../../../../../pages/user/form/user-form.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  private subscriptions: Subscription[] = [];

  currentUser: UserModel;

  constructor(
    private authService: AuthService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.authService.currentUser.pipe(filter((user) => !!user)).subscribe((user) => {
      this.currentUser = user;
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  showUpdateModal(): void {
    const modalRef = this.modalService.open(UserFormComponent, { size: 'lg', centered: true });
    modalRef.componentInstance.user = this.currentUser;

    const savedSubscription = modalRef.componentInstance.userSaved.subscribe((user: UserModel) => {
      this.currentUser = user;
      modalRef.close();
    });

    const canceledSubscription = modalRef.componentInstance.canceled.subscribe(() => {
      modalRef.close();
    });

    this.subscriptions.push(savedSubscription);
    this.subscriptions.push(canceledSubscription);
  }

  logout() {
    this.authService.logout();
  }

}

import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { CompanyModel } from '../models/company.model';
import { GridDataInterface, PaginationModel } from '../models/pagination.model';
import { Observable, of } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { catchError, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(
    private authService: AuthService,
    private api: BaseApiService,
    private translationService: TranslationService,
    private toast: ToastrService
  ) {
  }

  saveCompany(company: CompanyModel, queryParams: any = {}): Observable<CompanyModel | unknown> {
    const companyApiData = new CompanyModel({}).toApi(company);
    queryParams.expand = queryParams.expand ? queryParams.expand : 'image';

    return this.api.postFormData(`/companies${company.id ? '/' + company.id : ''}`, companyApiData, queryParams).pipe(
      map(response => {
        if (response?.body?.success == false) {
          this.toast.error('Ooops, something went wrong');
          return undefined;
        }

        return new CompanyModel(response.body);
      }),
      catchError((err) => {
        this.toast.error(err.error.errors.join("\n"));
        return of(undefined);
      }),
    );
  }

  removeCompany(id: string | number): Observable<CompanyModel | unknown> {

    return this.api.delete(`/companies/${id}`).pipe(
      map(response => {
        if (response?.body?.success == false) {
          this.toast.error('Ooops, something went wrong');
          return undefined;
        }

        return new CompanyModel(response.body);
      }),
      catchError((err) => {
        this.toast.error(err.error.errors?.join("\n") || err.message);
        return of(undefined);
      }),
    );
  }

  getCompanies(queryParams: any = {}): Observable<GridDataInterface | undefined> {
    queryParams.expand = queryParams.expand ? queryParams.expand : 'image,contacts.image,allowedDomains';

    return this.api.get(`/companies`, queryParams).pipe(
      map((response) => {
        if (response?.body?.success == false) {
          this.toast.error('Ooops, something went wrong');
          return undefined;
        }

        return {
          data: response.body.map(company => new CompanyModel(company)),
          pagination: PaginationModel.createFromHeaders(response.headers)
        };
      })
    );
  }

}

import { BaseModel } from './base.model';
import { ImageModel } from './image.model';

export class ContactModel extends BaseModel {

  id: number;
  name: string;
  phoneNumber: string;
  description: string;

  defaultPhotoUrl: string = './assets/images/placeholder-image.png';

  image: ImageModel;
  imageFile?: File;

  get childModels(): {} {
    return {
      image: ImageModel,
      imageFile: File,
    };
  }

  get unsafeApiAttributes() {
    return ['defaultPhotoUrl'];
  }

  get thumbUrl() {
    return this.image?.baseUrl || this.defaultPhotoUrl;
  }


}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { AttendeeResolver } from './shared/resolvers/attendee.resolver';
import { AppointmentResolver } from './shared/resolvers/appointment.resolver';
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { AttendeeFormComponent } from './pages/appointments/unauthorized/attendee-form/attendee-form.component';
import { AppointmentFormComponent } from './pages/appointments/unauthorized/appointment-form/appointment-form.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: ContentComponent,
    children: [
      {
        path: '',
        redirectTo: 'companies/display',
        pathMatch: 'full'
      },
      {
        path: 'appointments',
        loadChildren: () => import('./pages/appointments/appointment.module').then(m => m.AppointmentModule),
      },
      {
        path: 'users',
        loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'companies',
        loadChildren: () => import('./pages/company/company.module').then(m => m.CompanyModule)
      },
      {
        path: 'instances',
        loadChildren: () => import('./pages/instance/instance.module').then(m => m.InstanceModule)
      },
      {
        path: 'attendees',
        loadChildren: () => import('./pages/attendees/attendee.module').then(m => m.AttendeeModule)
      },
    ]
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'appointments/:appointment',
    component: AppointmentFormComponent,
    resolve: { appointment: AppointmentResolver}
  },
  {
    path: 'attendees/:attendee',
    component: AttendeeFormComponent,
    resolve: { attendee: AttendeeResolver}
  },
  {
    path: 'error',
    loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorModule)
  },
  {
    path: '**',
    redirectTo: 'error/404'
  }
];

@NgModule({
  imports: [[RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
  })],
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<form [formGroup]="form" (ngSubmit)="save()">
    <div class="modal-header">
        <h4 class="modal-title">{{(user?.fullname || 'Create User') | translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-4">
                <div class="form-group">
                    <label>{{'Profile Photo' | translate}}</label>
                    <app-image-upload [imageUrl]="user.thumbUrl" (image)="setImage($event)"
                    [showDescription]="false"
                        [placeholderImage]="user.defaultPhotoUrl" class="d-block mb-4">
                    </app-image-upload>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{'First Name' | translate}}</label>
                    <input formControlName="firstName" type="text" class="form-control"
                        placeholder="{{'First Name' | translate}}">
                    <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)" class="text-danger">
                        <small *ngIf="firstName.errors?.required">
                            {{'First name is required.' | translate}}
                        </small>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label>{{'Last Name' | translate}}</label>
                    <input formControlName="lastName" type="text" class="form-control"
                        placeholder="{{'Last Name' | translate}}">
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label>{{'Email' | translate}}</label>
                    <input formControlName="email" type="text" class="form-control"
                        placeholder="{{'john.doe@example.com' | translate}}">
                    <div *ngIf="email.invalid && (email.dirty || email.touched)" class="text-danger">
                        <small *ngIf="email.errors?.required">
                            {{'Email is required.' | translate}}
                        </small>
                        <small *ngIf="email.errors?.email">
                            {{'Please enter valid email address.' | translate}}
                        </small>
                    </div>
                </div>
            </div>

            <div class="col-md-6" *ngIf="!isCurrentUser()">
                <div class="form-group">
                    <label>{{'Role' | translate}}</label>
                    <select formControlName="role" class="form-control">
                        <option *ngIf="!user.id" value=""> </option>
                        <option *ngFor="let option of availableRoles" [ngValue]="option.id">
                            {{ option.name | translate }}
                        </option>
                    </select>
                    <div *ngIf="role.invalid && (role.dirty || role.touched)" class="text-danger">
                        <small *ngIf="role.errors?.required">
                            {{'Role is required.' | translate}}
                        </small>
                    </div>
                </div>
            </div>

            <div class="col-md-12" *ngIf="!isCurrentUser() && !isAdmin">
                <div class="form-group">
                    <label>{{'User company' | translate}}</label>
                    <ng-select formControlName="companyId" bindLabel="name" bindValue="id" [clearable]="false"
                        [items]="availableOnDisplayCompanies" placeholder="{{'Select user company' | translate}}">
                    </ng-select>
                    <div *ngIf="companyId.invalid && (companyId.dirty || companyId.touched)" class="text-danger">
                        <small *ngIf="companyId.errors?.required">
                            {{'Company is required.' | translate}}
                        </small>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label>{{'Password' | translate}}</label>
                    <input formControlName="password" type="password" class="form-control"
                        placeholder="{{'Type password' | translate}}">
                    <div *ngIf="password.invalid && (password.dirty || password.touched)" class="text-danger">
                        <small *ngIf="password.errors?.required">
                            {{'Password is required.' | translate}}
                        </small>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label>{{'Repeat Password' | translate}}</label>
                    <input formControlName="repeatPassword" type="password" class="form-control"
                        placeholder="{{'Repeat password' | translate}}">
                    <div *ngIf="repeatPassword.invalid && (repeatPassword.dirty || repeatPassword.touched)"
                        class="text-danger">
                        <small *ngIf="repeatPassword.errors?.mustMatch">
                            {{'Passwords must match.' | translate}}
                        </small>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label>{{'Phone' | translate}}</label>
                    <input formControlName="phone" type="text" class="form-control" mask="+00 (00) 000 00 00"
                        [dropSpecialCharacters]="['(', ')', ' ', '-']">
                </div>
            </div>

            <div class="col-md-6" *ngIf="!isCurrentUser()">
                <div class="form-group">
                    <label>{{'Active' | translate}}</label>
                    <label class="switch d-block">
                        <input type="checkbox" formControlName="isActive">
                        <span class="switch-state"></span>
                    </label>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer" [class] ="{'justify-content-between': canRemoveUser()}">
        <button
            *ngIf="canRemoveUser()"
            type="button"
            (confirm)="removeUser()"
            class="btn btn-outline-danger"
            [disabled]="isSaving"
            [swal]="{title: 'REMOVE_USER_TITLE' | translate, text: ('REMOVE_USER_TEXT' | translate : {user:user.fullname}), cancelButtonText: 'Cancel' | translate}"
        >
            {{'Delete' | translate}}
        </button>

        <div class="right-buttons">
            <button type="button" (click)="closeModal()" class="btn btn-light">
                {{'Cancel' | translate}}
            </button>
            <button type="submit" class="btn btn-primary" [disabled]="isSaving">
                {{'Save' | translate}}
                <span *ngIf="isSaving" class="spinner-border spinner-border-sm ml-2" role="status">
                    <span class="sr-only">Loading...</span>
                </span>
            </button>
        </div>
    </div>
</form>

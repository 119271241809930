<div ngbDropdown class="translate_wrapper dropdown-no-anchor" [class.active]="navServices.language">
  <div ngbDropdownToggle class="current_lang">
    <div class="lang">
      <i class="flag-icon flag-icon-{{selectedLanguage.icon}} mr-2"></i>
      <span class="text-uppercase font-weight-bold">{{selectedLanguage.code}}</span>
    </div>
  </div>
  <div ngbDropdownMenu class="dropdown-menu mt-3 border-0 shadow" [class.active]="navServices.language">
    <button ngbDropdownItem class="lang" data-value="en" (click)="changeLanguage(lang)" *ngFor="let lang of languages">
      <i class="flag-icon flag-icon-{{lang.icon}} mr-2"></i>
      <span>{{lang.language}}<span *ngIf="lang.type" class="ml-2 text-muted">({{lang.type}})</span></span>
    </button>
  </div>
</div>
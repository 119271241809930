import { BaseModel } from './base.model';
import { SaltoKsLockProviderModel } from './lock-providers/salto-ks-lock-provider.model';
import { SymmetryLockProviderModel } from './lock-providers/symmetry-lock-provider.model';

export interface ILockProvider {
  hasCardDispenser: boolean;
  hasElevatorGroup: boolean;
}

export class LockProviderConfigModel extends BaseModel {

  static LOCK_PROVIDER_SALTO = 'SaltoKs';
  static LOCK_PROVIDER_SYMMETRY = 'Symmetry';



  get initialValues() {
    return {
      hasCardDispenser: false,
      hasElevatorGroup: false
    }
  }

  static getLockProviders() {
    return [
      { 'id': LockProviderConfigModel.LOCK_PROVIDER_SALTO, 'name': 'Salto KS' },
      { 'id': LockProviderConfigModel.LOCK_PROVIDER_SYMMETRY, 'name': 'Symmetry (Securiton)' },
    ];
  }

  static createInstance(key: string, attributes: any) {

    switch (key) {
      case LockProviderConfigModel.LOCK_PROVIDER_SALTO: return new SaltoKsLockProviderModel(attributes);
      case LockProviderConfigModel.LOCK_PROVIDER_SYMMETRY: return new SymmetryLockProviderModel(attributes);
      default:
        return null;
    }
  }

}

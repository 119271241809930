import { BaseModel } from '../base.model';
import { ILockProvider } from '../lock-provider-config.model';

export class SaltoKsLockProviderModel extends BaseModel implements ILockProvider {

    baseUri: string;
    identityBaseUri: string;
    siteId: string;
    clientId: string;
    clientSecret: string;
    username: string;
    password: string;
    roleIds: string[];
    lockIds: string[];

    hasCardDispenser: boolean;
    hasElevatorGroup: boolean;

    get initialValues() {
        return {
            hasElevatorGroup: false,
            hasCardDispenser: false,
            roleIds: [],
            lockIds: []
        }
    }

}

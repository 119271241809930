export const environment = {
  production: false,
  title: 'Service Central',
  defaultAppLanguage: 'en',
  logoUrl: '/assets/images/logo/service-central-logo.png',
  logoDarkUrl: '/assets/images/logo/alto-logo.svg',
  logoOnlyUrl: '/assets/images/logo/service-central-logo-only.png',
  logoOnlyDarkUrl: '/assets/images/logo/alto-logo.svg',
  homepage: 'https://digitalreception.ch',
  appVersion: 'v001-service-central',
  USERDATA_KEY: 'authf-qaJ9jlkeCy',
  apiUrl: 'https://api.dev.service-central.digitalreception.ch/v1',
  apiClientId: 'service-central-dev',
  apiClientSecret: 'djenvOh5idgdNUlx4lx4MPkuTA79EjEJ',
  defaultCallerId: '+41 (44) 505 66 33',
  instanceReceptions: [
    {
      id: 'office',
      label: 'Office',
      callerId: '+41 (44) 505 66 33'
    },
    {
      id: 'warehouse',
      label: 'Warehouse',
      callerId: '+41 (44) 505 11 66'
    },
  ]
};

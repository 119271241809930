import { Component, OnInit } from '@angular/core';
import { InstanceModel } from '../../../../models/instance.model';
import { UserModel } from '../../../../models/user.model';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-instances',
  templateUrl: './instances.component.html',
  styleUrls: ['./instances.component.scss']
})
export class InstancesComponent implements OnInit {

  private user: UserModel;
  private unsubscribe: Subscription[] = [];

  instances: InstanceModel[] = [];
  selectedInstance: InstanceModel;

  constructor(
    private authService: AuthService,
    private userService: UserService,
  ) {

  }

  get selectedInstanceId() {
    return this.selectedInstance?.id;
  }

  set selectedInstanceId(id) {

  }

  ngOnInit() {
    this.unsubscribe.push(
      this.authService.currentUser.subscribe((user) => {
        if (user) {
          this.user = user;
          this.loadInstances();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((s) => s.unsubscribe());
  }

  switchInstance(instance) {
    this.selectedInstance = instance;
    this.userService.setInstance(instance.id).subscribe((user) => {
      window.location.reload();
    });
  }

  private loadInstances(): void {
    this.instances = this.user.instances || [];
    this.initSelectedInstance();
    this.putSelectedInstanceFirst();
  }

  private initSelectedInstance(): void {
    this.instances.forEach((instance: InstanceModel) => {
      if (this.user.instance?.id === instance.id) {
        this.selectedInstance = instance;
      }
    });
  }

  private putSelectedInstanceFirst() {
    if (this.selectedInstance) {
      const selectedIndex = this.instances.findIndex((instance) => instance.id === this.selectedInstance.id);

      if (selectedIndex !== -1) {
        this.instances.splice(selectedIndex, 1);
        this.instances.unshift(this.selectedInstance);
      }
    }
  }

}

<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
  <div class="header-wrapper row m-0">
    <app-search></app-search>
    <div class="header-logo-wrapper">
      <div class="logo-wrapper">
        <a routerLink='/'>
          <img class="img-fluid for-light" src="/assets/images/logo/logo.svg" alt="">
          <img class="img-fluid for-dark" src="/assets/images/logo/logo-dark.svg" alt="">
        </a>
      </div>
      <div class="toggle-sidebar" (click)="sidebarToggle()">
        <app-feather-icons [icon]="'align-center'" class="status_toggle middle"></app-feather-icons>
      </div>
    </div>
    <div class="nav-right col col-lg-12 pull-right right-header p-0 d-flex justify-content-between">
      <div class="pull-left d-flex">
        <app-instances></app-instances>
      </div>
      <ul class="nav-menus mr-0 d-flex">
        <li class="language-nav" (click)="languageToggle()">
          <app-languages></app-languages>
        </li>
        <li>
          <div class="mode" (click)="layoutToggle()">
            <i class="fa fa-moon-o" *ngIf="!dark"></i>
            <i class="fa fa-lightbulb-o" *ngIf="dark"></i>
          </div>
        </li>
        <li class="profile-nav onhover-dropdown p-0">
           <app-my-account></app-my-account>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- Page Header Ends -->
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { NavService, Menu } from '../../../../services/nav.service';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss']
})
export class LanguagesComponent implements OnInit {

  public language: boolean = false;

  public languages: any[] = [{
    language: 'English',
    code: 'en',
    icon: 'us'
  },
  {
    language: 'Deutsch',
    code: 'de',
    type: 'CH',
    icon: 'de'
  },
  {
    language: 'Français',
    code: 'fr',
    icon: 'fr'
  },
  {
    language: 'Italiano',
    code: 'it',
    icon: 'it'
  }]

  public selectedLanguage: any = {
    language: 'English',
    code: 'en',
    icon: 'us'
  }

  constructor(
    private translate: TranslationService,
    public navServices: NavService
  ) { }

  ngOnInit() {
    this.initSelectedLanguage();
  }

  changeLanguage(lang) {
    this.selectedLanguage = lang;
    this.translate.setLanguage(lang.code)
  }

  private initSelectedLanguage() {
    this.selectedLanguage = this.languages.find(({ code }) => code == this.translate.getSelectedLang());
  }

}

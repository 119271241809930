import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Observable, of } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { catchError, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslationService } from './translation.service';
import { AttendeeModel } from '../models/attendee.model';
import { GridDataInterface, PaginationModel } from '../models/pagination.model';

@Injectable({
  providedIn: 'root'
})
export class AttendeeService {

  constructor(
    private authService: AuthService,
    private api: BaseApiService,
    private translationService: TranslationService,
    private toast: ToastrService
  ) {
  }

  getAttendees(queryParams: any = {}): Observable<GridDataInterface | undefined> {
    return this.api.get(`/attendees`, queryParams).pipe(
      map((response) => {
        if (response?.body?.success == false) {
          this.toast.error('Ooops, something went wrong');
          return undefined;
        }

        return {
          data: response.body.map(appointment => new AttendeeModel(appointment)),
          pagination: PaginationModel.createFromHeaders(response.headers)
        };
      })
    );
  }

  getAttendee(attendeeHash: string | null, queryParams: any = {}): Observable<AttendeeModel | unknown> {
    return this.api.get(`/attendees/${attendeeHash}`, queryParams).pipe(
      map(response => {
        if (response?.body?.success == false) {
          this.toast.error('Ooops, something went wrong');
          return undefined;
        }

        return new AttendeeModel(response.body);
      }),
      catchError((err) => {
        this.toast.error(err.error.errors?.join("\n") || err.message);
        return of(undefined);
      }),
    );
  }

  saveAttendee(attendee: AttendeeModel, queryParams: any = {}): Observable<AttendeeModel | unknown> {
    return this.api.postFormData(`/attendees/${attendee.authHash}`, attendee.toApi(), queryParams).pipe(
      map(response => {
        if (response?.body?.success == false) {
          this.toast.error('Ooops, something went wrong');
          return undefined;
        }

        return new AttendeeModel(response.body);
      }),
      catchError((err) => {
        this.toast.error(err.error.errors.join("\n"));
        return of(undefined);
      }),
    );
  }

  sendBadgeReturnReminder(attendee: AttendeeModel, queryParams: any = {}): Observable<AttendeeModel | unknown> {
    return this.api.post(`/attendees/${attendee.id}/badge-return-reminder`, {}, queryParams).pipe(
      map(response => {
        if (response?.body?.success == false) {
          this.toast.error('Ooops, something went wrong');
          return undefined;
        }

        return new AttendeeModel(response.body);
      }),
      catchError((err, e) => {
        console.log(err.error, err.error.errors);
        this.toast.error(err.error.errors.join("\n"));
        return of(undefined);
      }),
    );
  }

  returnBadge(attendee: AttendeeModel, queryParams: any = {}): Observable<AttendeeModel | unknown> {
    return this.api.post(`/attendees/${attendee.id}/badge-return`, attendee.toApi(), queryParams).pipe(
      map(response => {
        if (response?.body?.success == false) {
          this.toast.error('Ooops, something went wrong');
          return undefined;
        }

        return new AttendeeModel(response.body);
      }),
      catchError((err) => {
        this.toast.error(err.error.errors.join("\n"));
        return of(undefined);
      }),
    );
  }

  revokeBadgeAccess(attendee: AttendeeModel, queryParams: any = {}): Observable<AttendeeModel | unknown> {
    return this.api.post(`/attendees/${attendee.id}/badge-revoke`, attendee.toApi(), queryParams).pipe(
      map(response => {
        if (response?.body?.success == false) {
          this.toast.error('Ooops, something went wrong');
          return undefined;
        }

        return new AttendeeModel(response.body);
      }),
      catchError((err) => {
        this.toast.error(err.error.errors.join("\n"));
        return of(undefined);
      }),
    );
  }

}

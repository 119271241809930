import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AttendeeModel } from '../../../shared/models/attendee.model';

@Component({
  selector: 'app-attendee-form-modal',
  templateUrl: './attendee-form-modal.component.html',
  styleUrls: ['./attendee-form-modal.component.scss']
})
export class AttendeeFormModalComponent implements OnInit {

  @Input() attendee: AttendeeModel;

  private attendeeImage: File;
  private subscriptions: Subscription[] = [];

  form: FormGroup;
  isSaving: boolean = false;
  isHost2: boolean = false;

  canceled = new EventEmitter<boolean>();
  attendeeSaved = new EventEmitter<AttendeeModel>();

  constructor() { }

  ngOnInit(): void {
    this.initForm();
    this.isHost2 = Boolean(this.attendee?.isHost);
  }

  private initForm() {
    this.form = new FormGroup({
      isHost: new FormControl(this.attendee?.isHost || 0),
      email: new FormControl(this.attendee?.profile?.email, [Validators.email, Validators.required]),
      firstName: new FormControl(this.attendee?.profile?.firstName, Validators.maxLength(45)),
      lastName: new FormControl(this.attendee?.profile?.lastName, Validators.maxLength(45)),
      personalTitle: new FormControl(this.attendee?.profile?.personalTitle, Validators.maxLength(45)),
    });
  }

  save() {
    if (this.form.valid) {
      this.attendee.setAttributes(this.form.value);
      this.attendee.profile.setAttributes(this.form.value);
      this.attendeeSaved.emit(this.attendee);
    }
  }

  setImage(file: any): void {
    this.attendeeImage = file;
  }

  closeModal() {
    this.canceled.emit(true);
  }

  get isHost() {
    return this.form.get('isHost');
  }

  get email() {
    return this.form.get('email');
  }

  get personalTitle() {
    return this.form.get('personalTitle');
  }

  get firstName() {
    return this.form.get('firstName');
  }

  get lastName() {
    return this.form.get('lastName');
  }


}

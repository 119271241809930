<!-- <div class="media profile-media">
  <img class="b-r-10 thumb-img" src="{{currentUser?.thumbUrl}}" alt="">
  <div class="media-body"><span>{{currentUser?.fullname}}</span>
    <p class="mb-0 font-roboto">{{currentUser?.role}} <i class="middle fa fa-angle-down"></i></p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <li><a href="javascript:void(0)" (click)="showUpdateModal()"><app-feather-icons [icon]="'user'"></app-feather-icons><span>{{'Profile' | translate}}</span></a></li>
  <li><a href="javascript:void(0)" (click)="logout()"><app-feather-icons [icon]="'log-out'"></app-feather-icons><span>{{'Log out' | translate}}</span></a></li>
</ul> -->

<div ngbDropdown class="dropdown-no-anchor">
  <div class="media profile-media" ngbDropdownToggle>
    <img class="b-r-10 thumb-img" src="{{currentUser?.thumbUrl}}" alt="">
    <div class="media-body"><span>{{currentUser?.fullname}}</span>
      <p class="mb-0 font-roboto">{{currentUser?.role}} <i class="middle fa fa-angle-down"></i></p>
    </div>
  </div>
  <div ngbDropdownMenu class="dropdown-menu mt-3 border-0 shadow">
    <button ngbDropdownItem (click)="showUpdateModal()">
      <app-feather-icons [icon]="'user'"></app-feather-icons>
      <span class="ml-2">{{'Profile' | translate}}</span>
    </button>
    <button ngbDropdownItem (click)="logout()">
      <app-feather-icons [icon]="'log-out'"></app-feather-icons>
      <span class="ml-2">{{'Log out' | translate}}</span>
    </button>
  </div>
</div>
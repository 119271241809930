import { BaseModel } from './base.model';

export class ReceptionConfigModel extends BaseModel {
  receptions: any[];
  defaultCallerId: string;
  defaultMeetingBufferHours: number;
  useLockCodeAsAuthCode: boolean;
  colors: any[];
  
  logoUrl: string;
  logoDarkUrl: string;
  logoOnlyUrl: string;
  logoOnlyDarkUrl: string;

  get initialValues() {
    return {
      defaultCallerId: '+41445051166',
      logoUrl: '/assets/images/logo/service-central-logo.png',
      logoDarkUrl: '/assets/images/logo/alto-logo.svg',
      logoOnlyUrl: '/assets/images/logo/service-central-logo-only.png',
      logoOnlyDarkUrl: '/assets/images/logo/alto-logo.svg',
      useLockCodeAsAuthCode: false,
      defaultMeetingBufferHours: 2,
      receptions: [],
    }
  }

}

// Localization is based on '@ngx-translate/core';
// Please be familiar with official documentations first => https://github.com/ngx-translate/core

import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment'

const LOCALIZATION_LOCAL_STORAGE_KEY = 'language';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {

  /**
   * Event that fires when the app is translated
   */
  static TRANSLATED = new EventEmitter<string>();
  private selectedLanguage: string;

  constructor(
    private translate: TranslateService
  ) { }

  initLanguage() {

    this.translate.setDefaultLang(this.getLanguageFromStorage() || this.getSelectedLang() || environment.defaultAppLanguage);
    this.translate.addLangs(['en', 'de', 'es', 'fr', 'pt', 'cn', 'ae']);
  }

  setLanguage(lang) {
    if (lang) {
      this.translateTo(lang);
      this.setLanguageToStorage(lang);
      if (lang !== this.selectedLanguage) {
        TranslationService.TRANSLATED.emit(lang);
        this.selectedLanguage = lang;
      }
    }
  }

  translateTo(lang) {
    this.translate.use(this.translate.getDefaultLang()); //fallback if language translation does not exists
    this.translate.use(lang);
  }

  getSelectedLang() {
    return this.translate.getDefaultLang();
  }

  /**
   * Returns selected language from local storage
   */
  getLanguageFromStorage(): any {
    return localStorage.getItem(LOCALIZATION_LOCAL_STORAGE_KEY);
  }

  setLanguageToStorage(lang) {
    localStorage.setItem(LOCALIZATION_LOCAL_STORAGE_KEY, lang);
  }


  public static getLanguage(): string {
    const storedLanguage = localStorage.getItem(LOCALIZATION_LOCAL_STORAGE_KEY);
    return storedLanguage || environment.defaultAppLanguage;
  }
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {catchError, filter, map, switchMap, take} from "rxjs/operators";
import {Router} from "@angular/router";
import {AuthModel} from "../models/auth.model";
import {environment} from "../../../environments/environment";
import {AuthService} from "../services/auth.service";
import {error} from "protractor";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private authData;
  private requestsToRefresh = [];

  constructor(
    private router: Router,
    private http: HttpClient,
    private authService: AuthService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let authReq = req;
    this.authData = AuthModel.getAuthFromLocalStorage();

    return next.handle(authReq).pipe(catchError((error) => {
      if (error instanceof HttpErrorResponse && !authReq.url.includes('/login') && (error.status == 401 || error.status == 403)) {
        return this.handle401Error(authReq, next);
      }
      return throwError(error);
    }));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {

    if (!this.isRefreshing) {
      this.isRefreshing = true;
      const refreshToken = this.authData.refreshToken;


      this.refreshTokenSubject.next(null);
      const token = this.authData.refreshToken;
      if (token) {
        return this.authService.refreshToken(refreshToken).pipe(
          switchMap((token) => {
            this.isRefreshing = false;
            this.refreshTokenSubject.next(token);
            return next.handle(this.addTokenHeader(request, token));
          }),
          catchError((err) => {
            this.isRefreshing = false;
           this.authService.logout();
            return throwError(err);
          })
        );
      } else {
        return this.router.navigate(['/auth/login']);
      }
    }
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: any) {
    return request.clone({headers: request.headers.set('Authorization', 'Bearer ' + token)});
  }


}





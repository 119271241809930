import { BaseModel } from './base.model';

export class AuditLogModel extends BaseModel {
  id: number;
  parentId: number;
  modelId: number;
  modelName: string;
  field: string;
  action: string;
  oldValue: string;
  newValue: string;
  createdAt: number;

  get createdAtDateObject(): Date | null {
    return this.createdAt ? new Date(this.createdAt * 1000) : null;
  }


}

import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { AppointmentModel } from '../models/appointment.model';
import { AppointmentService } from '../services/appointment.service';

@Injectable()
export class AppointmentResolver implements Resolve<AppointmentModel> {
  constructor(
    private appointmentService: AppointmentService,
    private router: Router,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<AppointmentModel> {
    let appointmentHash = route.paramMap.get('appointment');

    return new Promise((resolve, reject) => {
      this.appointmentService.getAppointment(appointmentHash, {expand: 'attendees.profile.image'}).subscribe((appointment: AppointmentModel) => {
        if (!appointment) {
          reject();
          return this.router.navigate(['404']);
        }
        resolve(appointment);
      }, (err: any) => {
        return this.router.navigate(['404']);
      });
    });
  }
}
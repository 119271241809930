import { environment } from '../../../environments/environment';
import { BaseModel } from './base.model';

export class AuthModel {

  private static authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  authToken: string;
  refreshToken: string;
  expiresIn: number;
  tokenType: string;
  scope: string;
  expiresAt: Date;

  static getAuthFromLocalStorage(): AuthModel {
    try {
      const authData = JSON.parse(
        localStorage.getItem(AuthModel.authLocalStorageToken)
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  static setAuthToLocalStorage(auth: AuthModel): boolean {
    if (auth && auth.authToken) {
      localStorage.setItem(AuthModel.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  static logout(): void {
    localStorage.removeItem(AuthModel.authLocalStorageToken);
    window.location.reload();
  }

  setAuth(response: any) {
    this.authToken = response.access_token;
    this.refreshToken = response.refresh_token;
    this.expiresIn = response.expires_in;
    this.tokenType = response.token_type;
    this.scope = response.scope;
    this.setExpireAt();

    return this;
  }

  setExpireAt() {
    const now = new Date();
    now.setTime(now.getTime() + this.expiresIn);

    this.expiresAt = now;
  }

  hasExpired() {
    const now = new Date();

    return now.getTime() > this.expiresAt?.getTime();
  }

}

export class AuthResetPassModel extends BaseModel {
  token: string;
  password: string;
  passwordRepeat: string;
}

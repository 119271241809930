import { HttpHeaders } from "@angular/common/http";
import { BaseModel } from "./base.model";

export interface GridDataInterface {
    data: any[];
    pagination: PaginationModel;
}

export class PaginationModel extends BaseModel {
    private currentPageHeaderName: string = 'X-Pagination-Current-Page';
    private pageCountHeaderName: string = 'X-Pagination-Page-Count';
    private perPageHeaderName: string = 'X-Pagination-Per-Page';
    private totalCountHeaderName: string = 'X-Pagination-Total-Count';

    currentPage: number;
    pageCount: number;
    perPage: number;
    totalCount: number;

    setFromHeaders(headers: HttpHeaders) {
        this.currentPage = parseInt(headers.get(this.currentPageHeaderName));
        this.totalCount = parseInt(headers.get(this.totalCountHeaderName));
        this.pageCount = parseInt(headers.get(this.pageCountHeaderName));
        this.perPage = parseInt(headers.get(this.perPageHeaderName));
    }

    static createFromHeaders(headers: HttpHeaders) {
        let instance = new PaginationModel();
        instance.setFromHeaders(headers);
        return instance;
    }
}
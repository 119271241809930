import { AppointmentModel } from './appointment.model';
import { AttendeeModel } from './attendee.model';
import { BaseModel } from './base.model';
import { CompanyModel } from './company.model';
import { ImageModel } from './image.model';

export class InstanceModel extends BaseModel {

  id: number;
  imageId: number;
  key: string;
  name: string;
  homepageUri: string;
  receptionEmail: string;
  receptionConfig: any;
  lockProvider: string;
  lockProviderConfig: any;

  defaultPhotoUrl: string = './assets/images/placeholder-image.png';

  image: ImageModel;
  companies: CompanyModel[];
  appointments: AppointmentModel[];
  attendees: AttendeeModel[];
  imageFile?: File;

  get childModels(): {} {
    return {
      image: ImageModel,
      companies: CompanyModel,
      appointments: AppointmentModel,
      attendees: AttendeeModel
    };
  }

  toApi(model?: this): {} {
    return {
      ...super.toApi(model),
      ...((model && model.imageFile) ? { image_file: model.imageFile } : {}),
    };
  }

  get thumbUrl() {
    return this.image?.baseUrl || this.defaultPhotoUrl;
  }

}

import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { catchError, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslationService } from './translation.service';
import { AttendeeModel } from '../models/attendee.model';
import { CardDispenserModel } from '../models/card-dispenser.model';

@Injectable({
  providedIn: 'root'
})
export class CardDispenserService {

  dispenserSubject: BehaviorSubject<CardDispenserModel> = new BehaviorSubject(undefined);

  constructor(
    private authService: AuthService,
    private api: BaseApiService,
    private translationService: TranslationService,
    private toast: ToastrService
  ) {
  }

  getStatus(): BehaviorSubject<CardDispenserModel> {
    return this.dispenserSubject;
  }

  refreshStatus(queryParams: any = {}) {
    this.api.get(`/card-dispensers/status`, queryParams).pipe(
      map((response) => {
        if (response?.body?.success == false) {
          this.toast.error('Ooops, something went wrong');
          return undefined;
        }
        return new CardDispenserModel(response?.body);
      }),
      catchError((err) => {
        this.toast.error(err.error.errors.join("\n"));
        return of(undefined);
      }),
    ).subscribe((value: CardDispenserModel) => {
      this.dispenserSubject.next(value);
    });
  }

}

import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {AuthService} from '../../../shared/services/auth.service';
import {UserModel} from '../../../shared/models/user.model';
import {UserService} from '../../../shared/services/user.service';
import {MustMatch} from '../../../shared/validators/passwordMatch';
import {filter} from 'rxjs/operators';
import {CompanyModel} from '../../../shared/models/company.model';
import {CompanyService} from '../../../shared/services/company.service';
import {GridDataInterface} from 'src/app/shared/models/pagination.model';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {

  @Input() user: UserModel;

  private userImage: File;
  private subscriptions: Subscription[] = [];
  private currentUser: UserModel;
  private onDisplayCompanies: CompanyModel[] = [];

  form: FormGroup;
  isSaving: boolean = false;

  canceled = new EventEmitter<boolean>();
  userSaved = new EventEmitter<UserModel>();

  constructor(
    private companyService: CompanyService,
    private userService: UserService,
    private authService: AuthService,
    private toast: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.initForm();
    this.initCurrentUser();
    this.initCompaniesOnDisplay();

  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  setImage(file: any): void {
    this.userImage = file;
  }

  closeModal() {
    this.canceled.emit(true);
  }

  save(): void {
    if (this.form.valid) {
      this.isSaving = true;
      this.user.setAttributes(this.form.value);
      if (this.userImage) {
        this.user.imageFile = this.userImage;
      }
      this.userService.saveUser(this.user).subscribe((user: UserModel) => {
        this.isSaving = false;
        if (user) {
          this.userSaved.emit(this.user);
          this.toast.success('User succesfully saved!');
        }
      }, (err) => {
        this.toast.error(err.error.errors.join('<br>'))
      });
    } else {
      this.form.updateValueAndValidity();
      this.form.markAllAsTouched();
    }
  }

  removeUser() {
    this.isSaving = true;
    this.userService.removeUser(this.user.id).subscribe((user: UserModel) => {
      this.isSaving = false;
      if (user) {
        this.user = user;
        this.userSaved.emit(this.user);
        this.toast.success('User succesfully removed!');
      }
    }, err => {
      this.toast.error(err.error.errors?.join("\n") || err.message);
    });
  }

  canRemoveUser() {
    return this.currentUser.isAdmin && !this.isCurrentUser()
  }

  isCurrentUser() {
    return this.currentUser?.id == this.user?.id;
  }

  get companyId() {
    return this.form.get('companyId');
  }

  get firstName() {
    return this.form.get('firstName');
  }

  get lastName() {
    return this.form.get('lastName');
  }

  get email() {
    return this.form.get('email');
  }

  get role() {
    return this.form.get('role');
  }

  get phone() {
    return this.form.get('phone');
  }

  get password() {
    return this.form.get('password');
  }

  get repeatPassword() {
    return this.form.get('repeatPassword');
  }

  get availableRoles() {
    return UserModel.availableRoles;
  }

  get availableOnDisplayCompanies() {
    return this.onDisplayCompanies;
  }

  get isAdmin() {
    return this.role.value == UserModel.ROLE_ADMIN;
  }

  private initForm() {
    this.form = new FormGroup({
      firstName: new FormControl(this.user?.profile?.firstName, Validators.required),
      lastName: new FormControl(this.user?.profile?.lastName),
      email: new FormControl(this.user?.profile?.email, [Validators.required, Validators.email]),
      role: new FormControl(this.user?.role, Validators.required),
      companyId: new FormControl(this.user?.company?.id, !this.user.isAdmin ? Validators.required : null),
      phone: new FormControl(this.user?.profile?.phone),
      password: new FormControl(null),
      repeatPassword: new FormControl(null),
      isActive: new FormControl(this.user?.isActive),
    }, {
      validators: MustMatch('password', 'repeatPassword')
    });

    this.form.get('role').valueChanges.subscribe(value => {
        if (value != UserModel.ROLE_ADMIN) {
          this.form.get('companyId').setValidators(Validators.required)
        } else {
          this.form.get('companyId').clearValidators();
          this.form.get('companyId').setErrors(null);
        }
      }
    );
  }

  private initCurrentUser() {
    this.authService.currentUser.pipe(filter((user) => !!user)).subscribe((user: UserModel) => {
      this.currentUser = user;
    });
  }

  private initCompaniesOnDisplay() {
    const params = {
      'per-page': 100,
      expand: 'image',
      is_on_display: 1
    };

    this.companyService.getCompanies(params).subscribe((payload: GridDataInterface) => {
      this.onDisplayCompanies = payload.data;
    });
  }

}

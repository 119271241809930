import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { AttendeeService } from '../../../../shared/services/attendee.service';
import { AttendeeModel } from '../../../../shared/models/attendee.model';

@Component({
  selector: 'app-attendee-form',
  templateUrl: './attendee-form.component.html',
  styleUrls: ['./attendee-form.component.scss']
})
export class AttendeeFormComponent implements OnInit {

  public title: string = '';
  public logoUrl: string = '';
  public homepage: string = '';
  public logoDarkUrl: string = '';
  public show: boolean = false;
  public form: FormGroup;

  attendee: AttendeeModel;

  showLoader: boolean = false;
  showSuccess: boolean = false;

  constructor(
    private attendeeService: AttendeeService,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit() {
    this.initProps();
    this.loadAttendee();
  }

  private initProps() {
    this.title = environment.title;
    this.logoUrl = environment.logoUrl;
    this.homepage = environment.homepage;
    this.logoDarkUrl = environment.logoDarkUrl;
  }

  private loadAttendee() {
    this.route.data.subscribe((data: { attendee: AttendeeModel }) => {
      this.attendee = data.attendee;
      this.initForm();
    });
  }

  private initForm() {
    this.form = new FormGroup({
      firstName: new FormControl(this.attendee?.profile?.firstName, Validators.maxLength(45)),
      lastName: new FormControl(this.attendee?.profile?.lastName, Validators.maxLength(45)),
      personalTitle: new FormControl(this.attendee?.profile?.personalTitle, Validators.maxLength(45)),
    });
  }

  saveAttendee() {
    this.showLoader = true;
    this.attendee.profile.setAttributes(this.form.value);

    this.attendeeService.saveAttendee(this.attendee).subscribe((attendee) => {
        this.showLoader = false;
        this.showSuccess = attendee ? true : false;
      }, (err: any) => {
        this.showLoader = false;
        this.showSuccess = false;
      });
  }

  get personalTitle() {
    return this.form.get('personalTitle');
  }

  get firstName() {
    return this.form.get('firstName');
  }

  get lastName() {
    return this.form.get('lastName');
  }

}

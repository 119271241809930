import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragulaModule } from 'ng2-dragula';
import { TranslateModule } from '@ngx-translate/core';
// Components
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CustomizerComponent } from './components/customizer/customizer.component';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ContentComponent } from './components/layout/content/content.component';
import { FullComponent } from './components/layout/full/full.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
// Header Elements Components
import { SearchComponent } from './components/header/elements/search/search.component';
import { LanguagesComponent } from './components/header/elements/languages/languages.component';
import { MyAccountComponent } from './components/header/elements/my-account/my-account.component';
import { InstancesComponent } from './components/header/elements/instances/instances.component';
// Directives
import { DisableKeyPressDirective } from './directives/disable-key-press.directive';
import { OnlyAlphabetsDirective } from './directives/only-alphabets.directive';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { ShowOptionsDirective } from './directives/show-options.directive';
import { DateInputConverter } from './directives/dateinput-converter.directive';
import { TimeInputConverter } from './directives/timeinput-converter.directive';
// Services
import { LayoutService } from './services/layout.service';
import { NavService } from './services/nav.service';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { AttendeePillsComponent } from './components/attendee-pills/attendee-pills.component';
import { NgSelectModule } from '@ng-select/ng-select';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    BreadcrumbComponent,
    CustomizerComponent,
    FeatherIconsComponent,
    FullComponent,
    ShowOptionsDirective,
    DisableKeyPressDirective,
    DateInputConverter, 
    TimeInputConverter,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    LoaderComponent,
    TapToTopComponent,
    SearchComponent,
    InstancesComponent,
    LanguagesComponent,
    MyAccountComponent,
    ImageUploadComponent,
    AttendeePillsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    DragulaModule.forRoot(),
    NgxMaskModule.forRoot(maskConfig),
    TranslateModule
  ],
  providers: [
    NavService,
    LayoutService
  ],
  exports: [
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    LoaderComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    TapToTopComponent,
    DisableKeyPressDirective,
    DateInputConverter, 
    TimeInputConverter,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    ImageUploadComponent,
    AttendeePillsComponent
  ],
})
export class SharedModule { }

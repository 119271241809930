<div class="d-flex">
      <div class="image-input image-input-outline img-thumbnail" [class.image-input-empty]="!imageUrl">
            <div class="image-input-wrapper" [class]="cssClass"
                  [style.backgroundImage]="getPic()"></div>
            <label class="btn btn-light-primary solid thumb-action-btn" data-action="change" data-toggle="tooltip" title=""
                  data-original-title="Change avatar">
                  <i class="icon-pencil"></i>
                  <input #imageInput type="file" accept=".svg, .png, .jpg, .jpeg" (change)="processFile(imageInput)">
            </label>
            <span class="btn btn-light-danger solid thumb-action-btn" data-action="cancel" data-toggle="tooltip" title=""
                  data-original-title="Cancel avatar">
                  <i class="icon-close"></i>
            </span>
            <span *ngIf="imageUrl" class="btn btn-light-danger solid thumb-action-btn" (click)="deletePic()" data-action="remove"
                  data-toggle="tooltip" title="" data-original-title="Remove avatar">
                  <i class="icon-close"></i>
            </span>
      </div>
      <div *ngIf="showDescription" class="d-flex ml-4 align-items-center">
            <span class="form-text text-muted">{{'Allowed file types: svg, png, jpg, jpeg.' | translate}}</span>
            <span class="form-text text-muted">{{'Max size: 5mb' | translate}}</span>
      </div>
</div>
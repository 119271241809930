import { BaseModel } from "./base.model";
import { ImageModel } from "./image.model";

export class ProfileModel extends BaseModel {
    id: number;
    personalTitle: string;
    firstName: string;
    lastName: string;
    language: string;
    email: string;
    phone: string;
    image: ImageModel;

    get childModels(): {} {
        return {
            image: ImageModel,
        };
    }
}
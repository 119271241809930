import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { AppointmentService } from '../../../../shared/services/appointment.service';
import { AttendeeModel } from '../../../../shared/models/attendee.model';
import { AppointmentModel } from '../../../../shared/models/appointment.model';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AttendeeFormModalComponent } from 'src/app/pages/attendees/form-modal/attendee-form-modal.component';
import { ProfileModel } from 'src/app/shared/models/profile.model';

@Component({
  selector: 'app-appointment-form',
  templateUrl: './appointment-form.component.html',
  styleUrls: ['./appointment-form.component.scss']
})
export class AppointmentFormComponent implements OnInit {

  private subscriptions: Subscription[] = [];

  public envTitle: string = '';
  public logoUrl: string = '';
  public homepage: string = '';
  public logoDarkUrl: string = '';
  public show: boolean = false;
  public form: FormGroup;

  appointment: AppointmentModel;

  showLoader: boolean = false;
  showRemoved: boolean = false;
  showSuccess: boolean = false;
  showForm: boolean = false;
  showStartTime: boolean = false;
  showEndTime: boolean = false;
  showLMeetingOptions: boolean = false;

  constructor(
    private appointmentService: AppointmentService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private toast: ToastrService
  ) {

  }

  ngOnInit() {
    this.initProps();
    this.loadAppointment();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  private initProps() {
    this.envTitle = environment.title;
    this.logoUrl = environment.logoUrl;
    this.homepage = environment.homepage;
    this.logoDarkUrl = environment.logoDarkUrl;
  }

  private loadAppointment() {
    this.route.data.subscribe((data: { appointment: AppointmentModel }) => {
      this.appointment = data.appointment;
      this.initForm();
      this.showForm = true;
      this.showEndTime = data.appointment.hasEndTime();
      this.showStartTime = data.appointment.hasStartTime();
    });
  }

  private initForm() {
    this.form = new FormGroup({
      title: new FormControl(this.appointment?.title, [Validators.required, Validators.maxLength(255)]),
      description: new FormControl(this.appointment?.description, Validators.maxLength(512)),
      startDateObject: new FormControl(this.appointment?.getStartTimeObject(), Validators.required),
      startTimeObject: new FormControl(this.appointment?.getStartTimeObject(), Validators.required),
      endDateObject: new FormControl(this.appointment?.getEndTimeObject()),
      endTimeObject: new FormControl(this.appointment?.getEndTimeObject()),
    });
  }

  showCreateAttendeeModal() {
    this.showAttendeeForm(new AttendeeModel({ profile: new ProfileModel() }));
  }

  showUpdateAttendeeModal(attendee: AttendeeModel) {
    this.showAttendeeForm(attendee);
  }

  canRemoveAttendee(attendee: AttendeeModel) {
    return true;
  }

  removeAttendee(attendee: AttendeeModel) {
    const index = this.appointment?.attendees.findIndex((el) => el.profile?.email == attendee.profile?.email);

    if (index !== -1) {
      this.appointment?.attendees.splice(index, 1);
    }
  }

  removeAppointment() {
    this.showLoader = true;

    this.appointmentService.removeAppointment(this.appointment.authHash)
      .subscribe((appointment) => {
        this.showLoader = false;
        this.showRemoved = true;
        this.showForm = false;
      }, (err: any) => {
        this.showLoader = false;
        this.showForm = true;
      });
  }

  protected showAttendeeForm(attendee: AttendeeModel): void {
    const modalRef = this.modalService.open(AttendeeFormModalComponent, { size: 'lg', centered: true });
    modalRef.componentInstance.attendee = attendee;

    const savedSubscription = modalRef.componentInstance.attendeeSaved.subscribe((attendee: AttendeeModel) => {
      this.updateAttendeeList(attendee);
      modalRef.close();
    });

    const canceledSubscription = modalRef.componentInstance.canceled.subscribe(() => {
      modalRef.close();
    });

    this.subscriptions.push(savedSubscription);
    this.subscriptions.push(canceledSubscription);
  }

  protected updateAttendeeList(attendee: AttendeeModel) {
    let existingAttendee = this.appointment?.attendees.findIndex((el) => el.profile?.email == attendee.profile?.email);

    if (existingAttendee !== -1) {
      this.appointment.attendees[existingAttendee] = attendee;
    } else {
      this.appointment.attendees.push(attendee);
    }

  }

  saveAppointment() {
    if (!this.form.valid) {
      return;
    }

    this.appointment.setStartDateISO(this.form.value?.startDateObject);
    this.appointment.setStartTimeISO(this.form.value?.startTimeObject);
    this.appointment.setEndDateISO(this.form.value?.endDateObject);
    this.appointment.setEndTimeISO(this.form.value?.endTimeObject);

    this.showLoader = true;
    this.appointment.hosts = this.appointment.attendees.filter((a) => a.isHost);
    this.appointment.visitors = this.appointment.attendees.filter((a) => !a.isHost);
    this.appointment.setAttributes(this.form.value);

    this.appointmentService.saveAppointmentCalendar(this.appointment)
      .subscribe((appointment) => {
        this.showLoader = false;
        this.showSuccess = !!appointment;
        this.showForm = !this.showSuccess;
      }, (err: any) => {
        this.showLoader = false;
        this.showSuccess = false;
        this.showForm = true;
      });
  }

  get title() {
    return this.form.get('title');
  }

  get description() {
    return this.form.get('descrition');
  }

  get startDateObject() {
    return this.form.get('startDateObject');
  }

  get endDateObject() {
    return this.form.get('endDateObject');
  }

  get startTimeObject() {
    return this.form.get('startTimeObject');
  }

  get endTimeObject() {
    return this.form.get('endTimeObject');
  }

}

<form [formGroup]="form" (ngSubmit)="save()">
    <div class="modal-header">
        <h4 class="modal-title">{{(company?.name || 'Create company') | translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-lg-4">
                <div class="form-group">
                    <label>{{'Company Photo' | translate}}</label>
                    <app-image-upload cssClass="rectangle contain" [imageUrl]="company.thumbUrl" [showDescription]="false"
                        (image)="setCompanyImage($event)" [placeholderImage]="company.defaultPhotoUrl" class="d-block mb-4">
                    </app-image-upload>
                </div>
            </div>

            <div class="col-lg-8">
                <div class="row">
                    <div [class]="showIsOnDisplayToggle ? 'col-md-6 col-lg-9' : 'col-md-6'">
                        <div class="form-group">
                            <label>{{'Name' | translate}}</label>
                            <input formControlName="name" type="text" class="form-control"
                                placeholder="{{'Company Name' | translate}}">
                            <div *ngIf="form.get('name').invalid && (form.get('name').dirty || form.get('name').touched)" class="text-danger">
                                <small *ngIf="form.get('name').errors?.required">
                                    {{'Company name is required.' | translate}}
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-3" *ngIf="showIsOnDisplayToggle">
                        <div class="form-group">
                            <label>{{'Is On Display' | translate}}</label>
                            <label class="switch d-block">
                                <input type="checkbox" formControlName="isOnDisplay" [(ngModel)]="showOnDisplay">
                                <span class="switch-state"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div *ngIf="lockProviderConfig?.hasElevatorGroup" class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label>{{'Elevator group' | translate}}</label>
                            <input formControlName="elevatorGroup" type="text" class="form-control" placeholder="{{'Accès Full' | translate}}">
                            <div class="text-muted small">
                                {{'Type elevator group exactly, without leading or trailing spaces.' | translate}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <h5>
                    {{ 'Allowed domains' | translate }}
                </h5>
                <p class="text-muted">
                    {{ 'COMPANY_FORM_DOMAIN_INFO' | translate }}
                </p>
                <div formArrayName="allowedDomains">
                    <div *ngFor="let contact of allowedDomains.controls; let i=index" [formGroupName]="i">
                        <div class="row d-flex align-items-center mb-2">
                            <input formControlName="id" type="hidden">

                            <div class="col-md-8">
                                <input formControlName="domain" type="text" class="form-control" placeholder="{{'example.com' | translate}}">
                                <div *ngIf="contact.get('domain').invalid && (contact.get('domain').dirty || contact.get('domain').touched)" class="text-danger">
                                    <small *ngIf="contact.get('domain').errors.required">{{ 'Fully qualified domain is required' }}</small>
                                    <small *ngIf="contact.get('domain').errors.max">{{ 'Domain must be less than 255 characters long' }}</small>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <button type="button" class="btn btn-block btn-light-danger" (click)="removeAllowedDomain(i)" [disabled]="isSaving">
                                    {{'Remove' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="offset-md-8 col-4">
                        <button type="button" class="btn btn-light float-right w-100" (click)="addAllowedDomain()" [disabled]="isSaving">
                            {{'Add domain' | translate}}
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-12" *ngIf="showOnDisplay">
                <h5>
                    {{ 'Reception information' | translate }}
                </h5>
                <p class="text-muted">
                    {{ 'COMPANY_FORM_RECEPTION_INFO' | translate }}
                </p>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{'Reception Number' | translate}}</label>
                            <input formControlName="receptionNumber" mask="+00 (00) 000 00 00"
                                [attr.disabled]="hasContacts ? true : null" 
                                [dropSpecialCharacters]="['(', ')', ' ', '-']" type="text" class="form-control"
                                placeholder="{{'Reception number' | translate}}">
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{'Reception Display' | translate}}</label>
                            <select formControlName="receptionDisplay" class="form-control">
                                <option value=""> </option>
                                <option *ngFor="let option of availableReceptionDisplays" [ngValue]="option.id">
                                    {{ option.name | translate }}
                                </option>
                            </select>
                            <div *ngIf="form.get('receptionDisplay').invalid && (form.get('receptionDisplay').dirty || form.get('receptionDisplay').touched)"
                                class="text-danger">
                                <small *ngIf="form.get('receptionDisplay').errors?.required">
                                    {{'Reception display mode is required.' | translate}}
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{'Reception Instance' | translate}}</label>
                            <select formControlName="receptionInstance" class="form-control">
                                <option *ngFor="let option of availableReceptionInstances" [ngValue]="option.id">
                                    {{ option.name | translate }} {{ option.callerId }}
                                </option>
                            </select>
                            <div *ngIf="form.get('receptionInstance').invalid && (form.get('receptionInstance').dirty || form.get('receptionInstance').touched)"
                                class="text-danger">
                                <small *ngIf="form.get('receptionInstance').errors?.required">
                                    {{'Reception instance is required.' | translate}}
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="form-group">
                            <label>{{'Reception Text' | translate}}</label>
                            <textarea formControlName="receptionText" type="text" class="form-control"
                                placeholder="{{'Reception text' | translate}}" rows="3">
                            </textarea>
                            <div *ngIf="form.get('receptionText').invalid && (form.get('receptionText').dirty || form.get('receptionText').touched)"
                                class="text-danger">
                                <small *ngIf="form.get('receptionText').errors?.required">
                                    {{'ReceptionText is required.' | translate}}
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12" *ngIf="showAddressFields">
                <div class="form-group">
                    <label>{{'Street' | translate}}</label>
                    <input formControlName="street" type="text" class="form-control">
                </div>
            </div>

            <div class="col-md-6" *ngIf="showAddressFields">
                <div class="form-group">
                    <label>{{'City' | translate}}</label>
                    <input formControlName="city" type="text" class="form-control">
                </div>
            </div>

            <div class="col-md-6" *ngIf="showAddressFields">
                <div class="form-group">
                    <label>{{'Zip code' | translate}}</label>
                    <input formControlName="zipcode" type="text" class="form-control">
                </div>
            </div>

            <div class="col-md-6" *ngIf="showAddressFields">
                <div class="form-group">
                    <label>{{'State' | translate}}</label>
                    <input formControlName="state" type="text" class="form-control">
                </div>
            </div>

            <div class="col-md-6" *ngIf="showAddressFields">
                <div class="form-group">
                    <label>{{'Country' | translate}}</label>
                    <input formControlName="country" type="text" class="form-control">
                </div>
            </div>

            <div class="col-12" *ngIf="showOnDisplay">
                <h5>
                    {{ 'Contact Information' | translate }}
                </h5>
                <p class="text-muted">
                    {{ 'MANY_CONTACTS_INFO.' | translate }}
                </p>
                <div formArrayName="contacts">
                    <div *ngFor="let contact of contacts.controls; let i=index" [formGroupName]="i">

                        <hr [attr.data-content]="'#' + (i + 1)" class="hr-text my-4">

                        <input formControlName="id" type="hidden">

                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label>{{'Contact Photo' | translate}}</label>
                                    <app-image-upload cssClass="rectangle contain" [imageUrl]="getContactById(contact.get('id').value).thumbUrl" [showDescription]="false"
                                        (image)="setContactImage($event, i)" [placeholderImage]="getContactById(contact.get('id').value).defaultPhotoUrl" class="d-block mb-3">
                                    </app-image-upload>
                                </div>
                                <button type="button" class="btn btn-block btn-sm btn-light-danger" (click)="removeContact(i)" [disabled]="isSaving">
                                    {{'Remove contact' | translate}}
                                </button>
                            </div>
                            <div class="col-lg">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>{{'Contact Name' | translate}}</label>
                                            <input formControlName="name" type="text" class="form-control"
                                                placeholder="{{'Contact Name' | translate}}">
                                        </div>
                                        <div *ngIf="contact.get('name').invalid && (contact.get('name').dirty || contact.get('name').touched)" class="text-danger">
                                            <small *ngIf="contact.get('name').errors.required">Contact name is required.</small>
                                            <small *ngIf="contact.get('name').errors.max">Contact name must be less than 45 chars long.</small>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>{{'Contact Number' | translate}}</label>
                                            <input formControlName="phoneNumber" mask="+00 (00) 000 00 00"
                                                [dropSpecialCharacters]="['(', ')', ' ', '-']" type="text" class="form-control"
                                                placeholder="{{'Contact number' | translate}}">
                                        </div>
                                        <div *ngIf="contact.get('phoneNumber').invalid && (contact.get('phoneNumber').dirty || contact.get('phoneNumber').touched)" class="text-danger">
                                            <small *ngIf="contact.get('phoneNumber').errors.required">Contact number is required.</small>
                                            <small *ngIf="contact.get('phoneNumber').errors.max">Contact number must be less than 45 chars long.</small>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>{{'Contact description' | translate}}</label>
                                            <textarea formControlName="description" type="text" class="form-control"
                                                placeholder="{{'Contact description' | translate}}" rows="3">
                                            </textarea>

                                            <div *ngIf="contact.get('description').invalid && (contact.get('description').dirty || contact.get('description').touched)" class="text-danger">
                                                <small *ngIf="contact.get('description').errors.required">Description is required.</small>
                                                <small *ngIf="contact.get('description').errors.max">Description must be less than 255 chars long.</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="offset-md-8 col-4">
                        <button type="button" class="btn btn-light float-right w-100" (click)="addContact()" [disabled]="isSaving">
                            {{'Add contact' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer justify-content-between">
        <button type="button" (confirm)="removeCompany()" class="btn btn-outline-danger" [disabled]="isSaving"
            [swal]="{title: 'REMOVE_COMPANY_TITLE' | translate, text: ('REMOVE_COMPANY_TEXT' | translate : {company:company.name}), cancelButtonText: 'Cancel' | translate}">
            {{'Delete' | translate}}
        </button>

        <div class="right-buttons">
            <button type="button" (click)="closeModal()" class="btn btn-light">
                {{'Cancel' | translate}}
            </button>
            <button type="submit" class="btn btn-primary" [disabled]="isSaving">
                {{'Save' | translate}}
                <span *ngIf="isSaving" class="spinner-border spinner-border-sm ml-2" role="status">
                    <span class="sr-only">Loading...</span>
                </span>
            </button>
        </div>
    </div>
</form>

import { BaseModel } from './base.model';

export class AddressModel extends BaseModel {
  id: number;
  street: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;

  get asString(): string {
    return [this.line1, this.line2].join(', ');
  }

  get asHtml(): string {
    return [this.line1, this.line2].join('<br>');
  }

  get line1(): string {
    return (`${this.street}`).trim();
  }

  get line2(): string {
    return (`${this.zipcode} ${this.city}`).trim();
  }

  get line3(): string {
    return (`${this.state ? this.state + ',' : ''} ${this.country}`).trim();
  }


}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(
        private router: Router,
        private userService: UserService,
        private authService: AuthService,
        private toast: ToastrService,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.authenticate(route, state);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.authenticate(childRoute, state);
    }

    private authenticate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const currentUser = this.userService.userInfo;
        const authData = this.authService.getAuthFromLocalStorage();

        if (currentUser?.id) {
            if (!currentUser.role) {
                this.toast.error('You are not allowed to login to our admin panel.');
                this.authService.logout();
                return false;
            }

            return this.checkRole(route, currentUser);
        }


        if (!currentUser?.id && !authData) {
            this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } }).then();
            return true;
        }

        return true;
    }

    private checkRole(route: ActivatedRouteSnapshot, currentUser): boolean {
        if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
            this.router.navigate(['/']).then();
            return false;
        }

        return true;
    }

}

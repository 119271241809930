import { BaseModel } from './base.model';
import { CompanyModel } from './company.model';
import { InstanceModel } from './instance.model';
import { LockProviderConfigModel } from './lock-provider-config.model';
import { ProfileModel } from './profile.model';
import { ReceptionConfigModel } from './reception-config.model';

export class UserModel extends BaseModel {

  static ROLE_ADMIN = 'admin';
  static ROLE_TENANT = 'tenant';

  id: number;
  instanceId: number;
  username: string;
  password: string;
  role: string;
  isActive: 0 | 1;

  defaultPhotoUrl: string = './assets/images/user/user.png';

  instance: InstanceModel;
  instances: InstanceModel[];
  profile: ProfileModel;
  company: CompanyModel;
  imageFile?: File;

  get childModels(): {} {
    return {
      profile: ProfileModel,
      company: CompanyModel,
      instance: InstanceModel,
      instances: InstanceModel,
    };
  }

  get unsafeApiAttributes() {
    return [
        'instance', 'instances',
    ];
}

  toApi(model?: this): {} {
    return {
      ...super.toApi(model),
      ...((model && model.imageFile) ? { image_file: model.imageFile } : {}),
    };
  }

  get thumbUrl() {
    return this.profile?.image?.baseUrl || this.defaultPhotoUrl;
  }

  get fullname(): string {
    return ((this.profile?.firstName?.toString() || "") + ' ' + (this.profile?.lastName?.toString() || "")).trim();
  }

  get isAdmin(): boolean {
    return this.role === UserModel.ROLE_ADMIN;
  }

  static get availableRoles() {
    return [
      { id: UserModel.ROLE_ADMIN, name: 'Admin' },
      { id: UserModel.ROLE_TENANT, name: 'Tenant' },
    ];
  }

  getReceptionConfig() {
    return new ReceptionConfigModel(this.instance?.receptionConfig);
  }

  getLockProviderConfig() {
    return LockProviderConfigModel.createInstance(this.instance?.lockProvider, this.instance?.lockProviderConfig);
  }

}

import { AddressModel } from './address.model';
import { BaseModel } from './base.model';
import { ImageModel } from './image.model';
import { ContactModel } from './contact.model';
import { environment } from '../../../environments/environment';

export class CompanyModel extends BaseModel {

  static DISPLAY_TENANT = 'tenant';
  static DISPLAY_FM_PROVIDER = 'fm-provider';

  id: number;
  name: string;
  elevatorGroup: string;
  meetingBufferHours: number;
  receptionNumber: string;
  receptionText: string;
  receptionInstance: string;
  receptionDisplay: string;
  isOnDisplay: 0 | 1;

  defaultPhotoUrl: string = './assets/images/placeholder-image.png';

  image: ImageModel;
  contacts: ContactModel[];
  allowedDomains: CompanyDomainModel[];
  address: AddressModel;
  imageFile?: File;

  get childModels(): {} {
    return {
      image: ImageModel,
      address: AddressModel,
      contacts: ContactModel,
      allowedDomains: CompanyDomainModel
    };
  }

  toApi(model?: this): {} {
    return {
      ...super.toApi(model),
      ...((model && model.imageFile) ? { image_file: model.imageFile } : {}),
    };
  }

  get thumbUrl() {
    return this.image?.baseUrl || this.defaultPhotoUrl;
  }

  static get availableReceptionDisplays() {
    return [
      { id: CompanyModel.DISPLAY_TENANT, name: 'Main Display' },
      { id: CompanyModel.DISPLAY_FM_PROVIDER, name: 'Help Display' },
    ];
  }

  isOnTenantDisplay() {
    return this.receptionDisplay == CompanyModel.DISPLAY_TENANT;
  }

}

export class CompanyDomainModel extends BaseModel {
  id: number;
  companyId: number;
  domain: string;
  receptionEmail: string;

  get unsafeApiAttributes() {
    return [];
  }
}
